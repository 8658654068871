<template>
  <div class="production_wrap">
    <div class = "box">
      <div class="about-title">领导团队</div>
      <div class = "block-line"></div>
      <div class="about-container">
        <p style="line-height:3;text-align:center;margin-top: 10px;">
          <span style="font-size:16px;">&nbsp;&nbsp;董事长：赵&nbsp;&nbsp;&nbsp;彦</span> 
        </p>
        <p style="line-height:3;text-align:center;">
          <span style="font-size:16px;">&nbsp;&nbsp;总经理：刘&nbsp;&nbsp;&nbsp;冰</span> 
        </p>
        <p style="line-height:3;text-align:center;">
          <span style="font-size:16px;">副总经理：隽海民</span> 
        </p>
        <p style="line-height:3;text-align:center;">
          <span style="font-size:16px;">工会主席：黄永武</span> 
        </p>
        <img width="100%" style="margin-top: 10px" src="../../assets/htmlimg/领导照片.jpg" alt="" />
      </div>
    </div>
    <CallMe />
    <MobileCallme />
  </div>
</template>

<script>
import CallMe from '@/components/callme/index'
import MobileCallme from '@/components/callme/MobileCallme.vue'
export default {
  data() {
    return {
    }
  },
  components: {
    CallMe,
    MobileCallme,
  },
}
</script>

<style lang="scss" scoped>
.production_wrap {
  padding-top: 200px;
  width: 100%;
}
</style>